import {signUpObjMuts, signUpValidMuts} from "@/state/modules/signUp/mutations";
import {signUpObjActs, signUpValidActs} from "@/state/modules/signUp/actions";

export const state = {
    signUpObj: {
        business_type: "",
        biznum: "",
        pw: "",
        martname: "",
        tel: "",
        stime: "",
        etime: "",
        ceoname: "",
        phone: "",
        imp_uid: "",
        addr: "",
        addr_detail: "",
        wido: "",
        gyeongdo: ""
    },
    signUpValid: {
        business_type: false,
        biznum: false,
        pw: false,
        martname: false,
        tel: false,
        stime: false,
        etime: false,
        ceoname: false,
        phone: false,
        imp_uid: false,
        addr: false,
        submit: false
    }
};

export const mutations = {
    ...signUpObjMuts,
    ...signUpValidMuts
};

export const actions = {
    ...signUpObjActs,
    ...signUpValidActs
};
